<template>
  <content-items-list
    content-type="galleries"
    content-type-singular="gallery"
    content-name="Galleries"
    content-name-singular="Gallery"
  />
</template>

<script>
import ContentItemsList from '@/views/content-item/content-items-list/ContentItemsList.vue'

export default {
  components: {
    ContentItemsList,
  },
}
</script>

<style scoped>

</style>
